.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 2rem;
  }
  
  .aboutimage img {
    border-radius: 50%;
    padding: 1rem;
    max-width: 100%; /* Ensure image does not exceed container width */
  }
  
  h4 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
    text-align: center; /* Center heading text */
  }
  
  p {
    font-size: 1rem; /* Adjust font size for smaller screens */
    padding: 1rem;
    text-align: justify; /* Justify paragraph text */
  }

  .mascot{
    border-radius: 60%;
    padding: 1rem;
    max-width: 80%;
  }
  