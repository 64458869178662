.contact {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    text-align: center;
  }
  
  h2 {
    font-size: 2rem;
    text-decoration: none;
  }
  
  h3 {
    margin-top: 1rem;
    text-decoration: none;
    word-spacing: -0.1em; /* Adjust word spacing as needed */
    overflow-wrap: break-word; /* Wrap long email address to avoid overflowing */
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  