.collection {
  height: 100vh;
}

.collectiontitle {
  font-family: "DM Serif Display", serif;
  padding: 2rem;
}

.domainnamescontainer {
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}

.domainbutton {
    --color: #560bad;
    font-family: inherit;
    text-align: center; 
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 4em;
    width: 12em;
    margin: 10px;
    padding: 3px;
    position: relative;
    overflow: hidden;
    border: 2px solid var(--color);
    transition: color .5s;
    z-index: 1;
    font-size: 1.5rem;
    border-radius: 6px;
    font-weight: bold; 
    color: var(--color);
  }
  
  
   
  .domainbutton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 110%; /* Cover entire button width */
    height: 110%; /* Cover entire button height */
    background: var(--color);
    border-radius: inherit;
    z-index: -1;
    transition: all .7s;
  }
   
   .domainbutton:hover {
    color: #fff;
   }
   
   .domainbutton:before {
    top: 150%;
    left: 150%;
    transition: all .7s;
   }
   
   .domainbutton:hover::before {
    top: -1px; 
    left: -1px; 
    width: 120%; 
    height: 150%; 
  }
   
   .domainbutton:active:before {
    background: #3a0ca3;
    transition: background 0s;
   }
  
