.header {
  font-family: "DM Serif Display", serif;
  height: 4rem;
  width: 100%;
  background-color: #d9d7f1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 2%;
  padding-right: 2%;
  align-items: center;
  text-align: center;
}

.header a {
  text-decoration: none;
  color: inherit;
}

.headeroptions {
  display: flex;
  gap: 5rem;
}

.collapse-button {
  display: none;
}

@media only screen and (max-width: 768px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    width: 100%;

  }

  .headeroptions {
    padding: 0; 
    flex-direction: column;
    gap: 1rem;
    display: none;
  }

  .collapsed {
    display: flex;
  }

  .collapse-button {
    text-align: center;
    display: block;
    margin: 0 auto; /* Add this line to center the button */
  }
}
